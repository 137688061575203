import React from 'react'
import {commonActions, mallActions} from "action";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {entries} from "reducer";
import {VsonTabs, GoodsList, WingLine} from 'components'

import './index.scss'

export interface P {
    changeCommonData: any,
    changeMallData: any,
    getStoreHome: any,
    page: number,
    id: string,
    goodsList: any[],
    shopType: number,
    goodsTypes: any[],
    className?: string
}

class StoreGoods extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            props: {goodsList, goodsTypes, className=''},
            onTabChange
        } = this
        return (
            <div className={`${className} store_goods`}>
                <WingLine>
                    店铺商品
                </WingLine>
                <VsonTabs name={'shopType'} options={goodsTypes} onChange={onTabChange} />
                <GoodsList list={goodsList} />
            </div>
        );
    }

    componentDidMount(): void {
        this.getInitData()
    }

    getInitData = async (newId?: string, newPage?: number, newType?: number) => {
        const {getStoreHome, id, page, shopType} = this.props
        const storeId = newId || id
        if (!storeId) return
        await getStoreHome(storeId, newType || shopType, newPage || page)
    }

    onTabChange = async () => {
        const {
            props: {changeMallData, changeCommonData},
            getInitData
        } = this
        await changeCommonData({
            page: 1,
            totalPage: 0
        })
        await changeMallData({goodsList: []})
        // await getInitData()
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        const {page: newPage, id: newId, shopType: newType} = nextProps
        const {page, id, shopType} = this.props
        if (newId && (newId !== id || page !== newPage || shopType !== newType)) {
            this.getInitData(newId, newPage, newType)
        }
    }
}

const mapStateToProps = (state: entries) => {
    const {goodsList} = state.mallReducer
    const {formFields: {shopType}, goodsTypes, page} = state.commonReducer
    return {
        page,
        goodsList,
        shopType,
        goodsTypes: [...goodsTypes].slice(0, 3)
    }
}

function mapDispatchToProps(dispatch: any) {
    const {getStoreHome, changeMallData} = mallActions
    const {changeCommonData} = commonActions
    return {
        ...bindActionCreators({
            changeMallData,
            changeCommonData,
            getStoreHome
        }, dispatch),
        dispatch
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreGoods)
