import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

import './index.scss'

export interface P {
    placeholder?: string,
    navData?: any,
    history: any
}

class SearchNav extends Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {props: {placeholder = '请输入搜索内容'}, navTo} = this;
        return (
            <div className='search_nav' onClick={navTo}>
                <img src="/imgs/search1.png" alt="" />
                <span>{placeholder}</span>
            </div>
        );
    }

    navTo = () => {
        const {navData = [1], history} = this.props
        history.push('/mall/search_goods/' + navData.join('/'))
    }


    componentDidMount(): void {
    }

    componentWillMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default withRouter(SearchNav)
