import {ActionType} from 'action'
import {Types} from 'action/common'

export interface tabBarEntry {
    text: string,
    page: string,
    isSelected: boolean
}

export interface commonEntry {
    tabBarList: tabBarEntry[],
    sortRules: any[],
    goodsType: number,
    goodsTypes: any[],
    auctionStatus: any[],
    goodsCategories: any[],
    comments: any[],
    page: number,
    totalPage: number,
    formFields: any,
    storeInfo: any,
    userInfo: any
}

export const initState = {
    userInfo: {},
    formFields: {
        numRange: [],
        goodsCategory:[]
    },
    storeInfo: {},
    page: 1,
    totalPage: 0,
    goodsCategories: [],
    comments: [],
    tabBarList: [
        {
            text: '图库',
            page: 'gallery',
            isSelected: false
        },
        {
            text: '博物馆',
            page: "collection",
            isSelected: false
        },
        {
            text: '乐淘',
            page: "mall_home",
            isSelected: true
        },
        {
            text: '晒宝',
            page: "treasure_forum",
            isSelected: false
        },
        {
            text: '我的',
            page: "mine",
            isSelected: false
        }
    ],
    goodsTypes: [
        {
            value: 1,
            src: '/imgs/shophome_paimai@2x.png',
            text: '拍卖',
            api: '/queryAuctionList',
            seek: "/seekAuction",
            detail: 'auction_goods_detail',
            add: '/addAuction',
            publish_list: "/manageAuctionList",
            detailapi: "/queryAuctionDetail"
        },
        {
            value: 2,
            src: '/imgs/shophome_yikoujia@2x.png',
            text: '一口价',
            api: '/queryFixedList',
            seek: "/seekFixed",
            detail: 'fixed_goods_detail',
            add: '/addFixed',
            publish_list: "/manageFixedList",
            detailapi: "/queryFixedDetail"
        },
        {
            value: 3,
            src: '/imgs/shophome_biaopin@2x.png',
            text: '批量',
            api: '/queryProductList',
            seek: "/seekProduct",
            detail: 'product_goods_detail',
            add: '/addProduct',
            publish_list: "/manageProductList",
            detailapi: "/queryProductDetail"
        },
        {
            value: 4,
            src: '/imgs/shophome_dingzhi@2x.png',
            text: '定制',
            api: '/queryAuctionList',
            add: '',
            detailapi: ''
        }
    ],
    auctionStatus: [
        {
            text: "预展中",
            value: 0,
            label: '未开始'
        },
        {
            text: "进行中",
            value: 1,
            label: '拍卖中'
        },
        {
            text: "已结束",
            value: 2,
            label: '已结拍'
        },
        {
            text: "已中拍",
            value: 3,
            label: '已中拍'
        }
    ],
    sortRules: [
        {
            text: '综合',
            value: 0
        },
        {
            text: '销量',
            value: 1
        },
        {
            text: '价格',
            value: 2
        },
        {
            text: '时间',
            value: 3
        }
    ]
}

export function commonReducer(
    state = initState,
    action: ActionType
) {
    const {
        type,
        payload = {}
    } = action
    const {
        index,
        list,
        totalPage,
        storeInfo,
        userInfo
    } = payload
    const {
        comments
    } = state
    switch (type) {
        case Types.selectTabBar:
            state.tabBarList = state.tabBarList.map((e, i) => {
                e.isSelected = i === index
                return e
            })
            break;
        case Types.getGoodsCategories:
            state.goodsCategories = list
            break;
        case Types.getPaginationData:
            state.totalPage = totalPage
            break;
        case Types.changeCommonData:
            Object.assign(state, {...payload})
            break;
        case Types.getStoreInfo:
            state.storeInfo = {...storeInfo}
            break;
        case Types.loginSuccess:
            Object.assign(state.userInfo, userInfo)
            break;
        case Types.getComments:
            // @ts-ignore
            state.comments = [...comments, ...list]
            break;
        default:
            break;
    }
    return {...state}
}


const orderTypes = [
    {
        type: 1,
        src: '/imgs/pending_payment.png',
        text: '待付款'
    },
    {
        type: 2,
        src: '/imgs/pending_delivered.png',
        text: '待发货'
    },
    {
        type: 3,
        src: '/imgs/pending_receipt.png',
        text: '待收货'
    },
    {
        type: 6,
        src: '/imgs/after_saled.png',
        text: '退款/售后'
    }
]
const orderAllTypes = [{
    type: 0,
    text: '全部',
    buy: "/buyerOrderList",
    sell: '/sellerOrderList'
},
    {
        type: 1,
        text: '待付款',
        buy: "/buyerObligation",
        sell: '/sellerObligation'
    },
    {
        type: 2,
        text: '待发货',
        buy: "/buyerStayReceive",
        sell: '/sellerDropShipping'
    },
    {
        type: 3,
        text: '待收货',
        buy: "/buyerDropShipping",
        sell: '/sellerStayReceive'
    },
    {
        type: 4,
        text: '待评价',
        buy: "/buyerEvaluate",
        sell: '/sellerEvaluate'
    },
    {
        type: 5,
        text: '已完成',
        buy: "/buyerFinish",
        sell: '/sellerFinish'
    },
    {
        type: 6,
        text: '退款/售后',
        buy: "/buyerRefundAfter",
        sell: '/sellerRefundAfter'
    }
]

const apiForAddrs = [{
    type: '收货',
    add: "/addDeliveryAddress",
    del: "/deleteDeliveryAddress",
    edit: "/updateDeliveryAddress",
    detail: "/queryDelAddOne",
    query: "/queryDelAddList",
},
    {
        type: '发货',
        add: "/addSellerDeliveryAddress",
        del: "/deleteSellerDeliveryAddress",
        edit: "/updateSellerDeliveryAddress",
        detail: "/querySellerDelAddOne",
        query: "/querySellerDelAddList",
    },
    {
        type: '退货',
        add: "/addSellerRetAddress  ",
        del: "/deleteSellerRetAddress",
        edit: "/updateSellerRetAddress",
        detail: "/querySellerRetAddOne",
        query: "/querySellerRetAddList",
    }
]

const identTypes = [{
    text: '个人认证',
    value: 0
},
    {
        text: '企业认证',
        value: 1
    }
]

const authStatus = [{
    text: '通过',
    value: 0
},
    {
        text: '审核中',
        value: 1
    },
    {
        text: '不通过',
        value: 2
    }
]

const payTypes = [{
    text: '支付宝',
    value: 1
},
    {
        text: '微信',
        value: 2
    }
]

const billTypes = [{
    text: '购物',
    value: 1
},
    {
        text: '手续费',
        value: 2
    },
    {
        text: '提现',
        value: 3
    },
    {
        text: '充值',
        value: 4
    },
    {
        text: '退款',
        value: 5
    },
    {
        text: '消保金',
        value: 6
    }
]

const orderStatus = {
    statu_1: {
        tip: '待付款',
        text: "待付款"
    },
    statu_2: {
        tip: '待发货',
        text: "待发货"
    },
    statu_3: {
        tip: '待收货',
        text: "待收货"
    },
    statu_4: {
        tip: '待评价',
        text: "待评价"
    },
    statu_5: {
        tip: '退款中',
        text: "退款中"
    },
    statu_6: {
        tip: '交易成功',
        text: "已完结"
    },
    statu_7: {
        tip: '延迟收货',
        text: "延迟收货"
    },
    statu_9: {
        tip: '交易关闭',
        text: "取消订单"
    }
}

const scoreGrade = [{
    text: "非常差",
    value: 1
},
    {
        text: "差",
        value: 2
    },
    {
        text: "一般",
        value: 3
    },
    {
        text: "好",
        value: 4
    },
    {
        text: "非常好",
        value: 5
    }
]

const msgTypes = [{
    text: "聊天消息",
    value: 0
},
    {
        text: "系统消息",
        value: 1
    },
    {
        text: "平台公告",
        value: 3
    }
]


const publishGoodsStatus = [{
    text: "待上架",
    value: 3
},
    {
        text: "上架",
        value: 2
    },
    {
        text: "已下架",
        value: 1
    },
    {
        text: "草稿箱",
        value: 0
    },
    {
        text: "已中拍",
        value: 4
    }
]

const takePartAuctionStatus = [{
    text: "已中拍",
    value: 3
},
    {
        text: "进行中",
        value: 1
    },
    {
        text: "已结束",
        value: 2
    }
]

