import React from 'react'
import {Flex} from 'antd-mobile'

import './index.scss'

export interface P {
    children: any,
    className?: string
}

class AllowRight extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {children, className = ''} = this.props
        return (
            <Flex className={className}>
                {
                    children
                }
                <img className={'allow_right_img'} src="/imgs/right.png" alt="" />
            </Flex>
        );
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default AllowRight
