import {ActionType} from 'action'
import {Types} from 'action/mall'

export const initState: MallEntry = {
    showGoodsTypes: false,
    bannerList: [],
    goodsList: [],
    auctionScenes: [],
    goods: {}
};

export interface MallEntry {
    showGoodsTypes: boolean,
    bannerList: any[],
    goodsList: any[],
    auctionScenes: any[],
    goods: any
}

export function mallReducer(
    state = initState,
    action: ActionType
) {
    const {
        type, payload = {}
    } = action
    const {
        goodsList
    } = state
    const {
        list = [],
        goods = {},
        auctionScenes
    } = payload
    switch (type) {
        case Types.getBanners:
            state.bannerList = [...list]
            break;
        case Types.getHomeGoods:
            state.goodsList = [...list]
            break;
        case Types.toggleGoodsTypes:
            state.showGoodsTypes = !state.showGoodsTypes
            break;
        case Types.getGoodsList:
            state.goodsList = [...goodsList, ...list]
            break;
        case Types.changeMallData:
            Object.assign(state, payload)
            break;
        case Types.getGoodsDetail:
            state.goods = {...goods}
            break;
        case Types.getAuctionScenes:
            state.auctionScenes = [...auctionScenes]
            break;
        default:
            break;
    }
    return {...state}
}

