import React from 'react'
import {Flex} from 'antd-mobile'
import {showDownloadModal} from 'components'
import {withRouter} from 'react-router-dom'

import './index.scss'

export interface P {
    store: any,
    className?: string,
    history: any
}

class StoreTop extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            props: {
                store,
                className = ''
            },
            navTo
        } = this
        return (
            <div className={`item padding store_header ${className}`} onClick={navTo}>
                <Flex className={'top'}>
                    <img className={'logo'} src={store.storeImg && store.storeImg.medium} alt="" />
                    <Flex.Item className={'padding_lr'}>
                        <div className={'name single_line'}>{store.storeName}</div>
                        <div className={'identity'}>
                            <img src="/imgs/auth.png" alt="" />
                            <span>{store.identType === '0' ? '个人' : '企业'}认证</span>
                        </div>
                    </Flex.Item>
                    <div className={'oprs'}>
                        <div className={'state5'} onClick={showDownloadModal}>关注</div>
                        <div className={'state5'} onClick={showDownloadModal}>客服</div>
                    </div>
                </Flex>
                <Flex className={'nums padding_ud'}>
                    <Flex.Item>
                        <div>{store.cashDeposit}</div>
                        <div className={'col_3'}>消保金</div>
                    </Flex.Item>
                    <Flex.Item className={'bd_lr'}>
                        <div>{store.storeGrade || 5}</div>
                        <div className={'col_3'}>店铺评分</div>
                    </Flex.Item>
                    <Flex.Item>
                        <div>{store.turnover || 0}</div>
                        <div className={'col_3'}>成交量</div>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }

    navTo = () => {
        const {history, store: {storeId}} = this.props
        history.push('/mall/store_home/' + storeId)
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default withRouter(StoreTop)
