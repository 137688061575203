import {mallApis} from 'apis'
import {initState as commonState} from 'reducer/common'
import * as commonActions from './common'
import {WxConfig} from 'utils'

const {goodsTypes} = commonState

export const Types = {
    getBanners: 'GET_BANNERS',
    getHomeGoods: "GET_HOME_GOODS",
    toggleGoodsTypes: 'TOGGLE_GOODS_TYPES',
    getGoodsList: 'GET_GOODS_LIST',
    changeMallData: 'CHANGE_MALL_DATA',
    getGoodsDetail: 'GET_GOODS_DETAIL',
    getAuctionScenes: 'GET_AUCTION_SCENES'
}


const getBanners = () => async (dispatch: any) => {
    const list = await mallApis.getBanners()
    dispatch({
        type: Types.getBanners,
        payload: {
            list
        }
    })
}

const getHomeGoods = () => async (dispatch: any) => {
    const {list} = await mallApis.getHomeGoods()
    await dispatch({
        type: Types.getHomeGoods,
        payload: {
            list
        }
    })
}

const toggleGoodsTypes = () => async (dispatch: any) => {
    await dispatch({
        type: Types.toggleGoodsTypes
    })
}

const getGoodsList = (obj: any) => async (dispatch: any) => {
    if (obj.data.page === 1) {
        await dispatch({
            type: Types.changeMallData,
            payload: {
                goodsList: []
            }
        })
    }
    const {list} = await commonActions.getPaginationData(obj)(dispatch)
    await dispatch({
        type: Types.getGoodsList,
        payload: {
            list
        }
    })
}

const changeMallData = (data: object) => async (dispatch: any) => {
    await dispatch({
        type: Types.changeMallData,
        payload: data
    })
}

const getGoodsDetail = (shopId: string, type: number) => async (dispatch: any) => {
    // await commonActions.wxOAuth2()(dispatch)
    const {goods} = await mallApis.getGoodsDetail(goodsTypes[type - 1].detailapi, shopId)
    document.title = goods.shopName
    await dispatch({
        type: Types.getGoodsDetail,
        payload: {
            goods
        }
    })
    const {shopName, shopPriImg, remark, explain} = goods
    await WxConfig.onShare({
        title: shopName,
        desc: remark || explain || shopName,
        link: window.location.href,
        imgUrl: shopPriImg[0].medium
    })
    return {...goods}
}

const doCollectionGoods = (shopId: string, shopType: number, status: number) => async (dispatch: any) => {
    const res = await mallApis.doCollectionGoods(shopId, shopType, status)
}


const getStoreHome = (storeId: string, shopType: number, page: number, sort: number) => async (dispatch: any) => {
    const {shopList} = await commonActions.getStoreHome(storeId, shopType, page, sort = 0)(dispatch)
    await dispatch({
        type: Types.getGoodsList,
        payload: {
            list: shopList
        }
    })
}

const getAuctionScenes = (storeId: string) => async (dispatch: any) => {
    const {auctionScenes} = await mallApis.getAuctionScenes(storeId)
    await dispatch({
        type: Types.getAuctionScenes,
        payload: {
            auctionScenes
        }
    })
}


export {
    getBanners,
    getHomeGoods,
    toggleGoodsTypes,
    getGoodsList,
    changeMallData,
    getGoodsDetail,
    doCollectionGoods,
    getStoreHome,
    getAuctionScenes
}
