import React from 'react'
import {Carousel} from "antd-mobile";

import './index.scss'

export interface P {
    options: any[]
}

class VsonBanner extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            props: {options = []},
            navTo
        } = this
        return (
            <Carousel
                autoplay
                infinite
                slideWidth={1}
                className={`banner_wrapper ${options.length>1 ? '' : 'single_img'}`}
            >
                {options.map((e, i) => (
                    <img key={i} className={'banner_img'} alt='' src={e.medium || e.img.medium}
                         onClick={() => navTo(e)} />
                ))}
            </Carousel>
        );
    }

    navTo = (obj: any) => {
        const {url} = obj
        if (url) {
            window.open(url)
        }
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default VsonBanner
