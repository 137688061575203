import * as commonActions from './common'
import * as mallActions from './mall'

const {Types:commonTypes} = commonActions
const {Types:mallTypes} = mallActions

export interface ActionType {
    type: string,
    payload: any
}

export {
    mallTypes,
    mallActions,
    commonTypes,
    commonActions
}
