import {commonApis} from 'apis'
import {localDB, Utils, WxConfig} from 'utils'

export const Types = {
    selectTabBar: 'SELECT_TABBAR',
    getGoodsCategories: 'GET_GOODS_CATEGORIES',
    changeCommonData: 'CHANGE_COMMON_DATA',
    getPaginationData: 'GET_PAGINATION_DATA',
    getStoreInfo: 'GET_STORE_INFO',
    getComments: 'GET_COMMENTS',
    loginSuccess: 'LOGIN_SUCCESS',
    getWxConfig: 'GET_WX_CONFIG'
}

const selectTabBar = (index: number) => async (dispatch: any) => {
    await dispatch({
        type: Types.selectTabBar,
        payload: {
            index
        }
    })
}

const getGoodsCategories = () => async (dispatch: any) => {
    const {goodsCategories} = await commonApis.getGoodsCategories()
    await dispatch({
        type: Types.getGoodsCategories,
        payload: {
            list: goodsCategories
        }
    })
    return {goodsCategories}
}
const getPaginationData = (obj: object): any => async (dispatch: any) => {
    const {totalPage, list} = await commonApis.getPaginationData(obj)
    await dispatch({
        type: Types.getPaginationData,
        payload: {
            totalPage
        }
    })
    return {list}
}

const changeCommonData = (data: object) => async (dispatch: any) => {
    const {payload} = await dispatch({
        type: Types.changeCommonData,
        payload: data
    })
    return {...payload}
}

const getStoreHome = (storeId: string, shopType: number, page: number, sort: number) => async (dispatch: any) => {
    const {shopList = [], totalPage, ...storeInfo} = await commonApis.getStoreHome({storeId, shopType, page, sort})
    await dispatch({
        type: Types.getStoreInfo,
        payload: {
            storeInfo
        }
    })
    await dispatch({
        type: Types.changeCommonData,
        payload: {
            totalPage: parseInt(totalPage)
        }
    })
    return {shopList}
}

const getComments = (data: any) => async (dispatch: any) => {
    const {storeId} = data
    const {list} = await getPaginationData({
        url: storeId ? '/queryStoreComment' : '/queryFixedReview',
        data
    })(dispatch)
    await dispatch({
        type: Types.getComments,
        payload: {
            list
        }
    })
    return {comments: list}
}

const login = (data: any) => async (dispatch: any) => {
    const {tk, ...userInfo} = await commonApis.login(data)
    await dispatch({
        type: Types.loginSuccess,
        payload: {
            userInfo
        }
    })
    await localDB.DBSetter('token', tk)
    return {tk, userInfo}
}

const getWxConfig = (data = {}) => async (dispatch: any) => {
    const {appId, nonceStr, signature, timestamp, ...userInfo} = await commonApis.getWxConfig(data)
    const wxConfig = {
        appId, timestamp, nonceStr, signature
    }
    await dispatch({
        type: Types.getWxConfig,
        payload: {
            userInfo
        }
    })
    await localDB.DBSetter('userInfo', userInfo)
    return {wxConfig}
}

const wxOAuth2 = () => async (dispatch: any) => {
    const {search, href, origin, pathname} = window.location
    const {code, state} = Utils.getParamsBySearch(search)
    if (!!code && state === 'authorized') {
        const {wxConfig} = await getWxConfig({code, url: encodeURIComponent(href)})(dispatch)
        await WxConfig.config(wxConfig)
        return
    }
    const oauth2Host = 'https://open.weixin.qq.com/connect/oauth2/authorize?'
    const obj = {
        appid: 'wx22dc4d474b52a4b2',
        redirect_uri: encodeURIComponent(window.location.href),
        response_type: 'code',
        scope: 'snsapi_userinfo',
        state: 'authorized#wechat_redirect',
        connect_redirect: 1
    }
    window.location.href = oauth2Host + Utils.spliceParams(obj)
}


export {
    selectTabBar,
    getGoodsCategories,
    getPaginationData,
    changeCommonData,
    getStoreHome,
    getComments,
    login,
    getWxConfig,
    wxOAuth2
}
