import React from 'react'
import {Flex} from 'antd-mobile'
import {AllowRight} from 'components'

import './index.scss'

export interface P {
    className?: string
}

class AuctionProcess extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {className = ''} = this.props
        return (
            <div className={`item process ${className}`}>
                <AllowRight className={'top padding'}>
                    <Flex.Item>拍卖说明</Flex.Item>
                    <a href="http://www.leshoucang.com.cn/lscAgreement/paimaiInstructions.html">更多说明</a>
                </AllowRight>
                <Flex className={'processes bd_u padding_ud'}>
                    <div>
                        <img alt={''} src="/imgs/paimaiLC_1.png" />
                        <div>参与出价</div>
                    </div>
                    <img alt={''} src="/imgs/paimai_jiantou.png" />
                    <div>
                        <img alt={''} src="/imgs/paimaiLC_2.png" />
                        <div>价高者得</div>
                    </div>
                    <img alt={''} src="/imgs/paimai_jiantou.png" />
                    <div>
                        <img alt={''} src="/imgs/paimaiLC_3.png" />
                        <div>支付货款</div>
                    </div>
                    <img alt={''} src="/imgs/paimai_jiantou.png" />
                    <div>
                        <img alt={''} src="/imgs/paimaiLC_4.png" />
                        <div>获得宝贝</div>
                    </div>
                </Flex>
            </div>
        );
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default AuctionProcess
