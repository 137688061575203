import React from 'react'
import {Range} from 'antd-mobile'
import {commonActions} from 'action'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import './index.scss'
import {entries} from "../../reducer";

export interface S {
    showTip: boolean
}

export interface P {
    min?: number,
    max?: number,
    name?: string,
    changeCommonData: any,
    onChange: any,
    formFields: any
}

const style = {height: '1rem', width: '1rem', marginTop: '-.5rem'}

class VsonRange extends React.Component<P, S> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {
            showTip: false
        };
    }

    onAfterChange = async (val: any) => {
        const {
            formFields,
            name = 'numRange', changeCommonData, onChange = () => {
            }
        } = this.props
        formFields[name] = val
        await changeCommonData({formFields: {...formFields}})
        onChange(val)
        this.setState({showTip: false})
    }
    onChange = () => {
        this.setState({showTip: true})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            state: {showTip},
            props: {
                min = 0, max = 1000000
            }, onAfterChange,
            onChange
        } = this
        // @ts-ignore
        return <Range
            handleStyle={[style, style]}
            min={min}
            max={max}
            onAfterChange={onAfterChange}
            onChange={onChange}
            className={showTip ? 'showTip' : ''} />;
    }

    componentDidMount(): void {

    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

const mapStateToProps = (state: entries) => {
    const {formFields} = state.commonReducer
    return {
        formFields
    }
}

function mapDispatchToProps(dispatch: any) {
    const {changeCommonData} = commonActions
    return {
        ...bindActionCreators({changeCommonData}, dispatch),
        dispatch
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VsonRange)

