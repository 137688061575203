import React from 'react'
import {Flex} from 'antd-mobile'
import {Link} from 'react-router-dom'
import {AllowRight} from 'components'

import './index.scss'

export interface P {
    length: number
}

class StoreCommentTop extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            props: {length = 10}
        } = this
        return (
            <AllowRight className={'item padding_lr store_comment_top'}>
                <Flex.Item className={'title'}>店铺评价({length})</Flex.Item>
                <Link to={''} className={'right_tip'}>查看全部评价</Link>
            </AllowRight>
        );
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default StoreCommentTop
