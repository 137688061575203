import Mall from "./mall";
import {asyncComponent} from 'components'

const Home = asyncComponent(() => import("./home"));
const Login = asyncComponent(() => import("./login"));
const Register = asyncComponent(() => import("./register"));

export {
    Home,
    Login,
    Register,
    Mall
}
