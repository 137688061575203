import React from 'react'
import {entries} from "reducer";
import {mallActions} from "action";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {showDownloadModal} from 'components'

import './index.scss'

export interface P {
    state: 1 | 2,
    id: string,
    type: number,
    doCollectionGoods: any
}

class CollectionGoods extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            props: {state = 1},
            onCollection
        } = this
        return (
            <div className={'collection'} onClick={onCollection}>
                <img src={'/imgs/collection' + (state ? '' : '_active') + '.png'} alt="" />
                <div className={`${state ? '' : 'active'}`}>收藏</div>
            </div>
        );
    }

    onCollection = async () => {
        const {doCollectionGoods, state, type, id} = this.props
        showDownloadModal()
        //await doCollectionGoods(id, type, state)
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

const mapStateToProps = (state: entries) => {
    return {}
}

function mapDispatchToProps(dispatch: any) {
    const {doCollectionGoods} = mallActions
    return {
        ...bindActionCreators({doCollectionGoods}, dispatch),
        dispatch
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionGoods)
