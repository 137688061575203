import React from 'react'

import './index.scss'

export interface P {
    goods: any,
    className?: string,
    type: number
}

class GoodsDetail extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            goods: {
                size = '',
                place = '',
                explain = '',
                remark = '',
                shopDetImg = [],
                shopVedio = []
            }, type, className = ''
        } = this.props
        return (
            <div className={`${className} goods_detail_component padding_ud`}>
                <div className={'padding_lr infos'}>
                    <div className={'header'}>{type === 1 ? '拍品' : "商品"}详情</div>
                    <div className={'info padding'}>【尺寸】{size}</div>
                    <div className={'info padding'}>【产地】{place}</div>
                    <div className={'info padding'}>【描述】{explain}</div>
                    <div className={'info padding'}>【备注】{remark}</div>
                </div>
                <div className={'imgs'}>
                    {
                        shopDetImg.map((e: any, i: number) =>
                            <img className={'margin_u lazy_img'} alt='' src={'/imgs/logo_bg.png'} data-src={e.medium} key={i} />
                        )
                    }
                </div>
                <div className={'videos'}>
                    {
                        shopVedio.map((e: any, i: number) =>
                            <video className={'margin_u'} src={e.medium} key={i} />
                        )
                    }
                </div>
            </div>
        );
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default GoodsDetail
