import React from 'react';
import './App.scss';

import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import {Home, Login, Register, Mall} from 'pages';

const App: React.FC = () => {
    return (
        // basename={'/h5'}
        <BrowserRouter  forceRefresh >
            <Switch>
                <Route path="/home/:sub" exact component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                {
                    Object.entries(Mall).map(([pageName, {page, params}], i) => (
                        <Route key={i} path={'/mall/' + pageName + params} component={page} />
                    ))
                }
                <Redirect to="/home/mall_home" />
            </Switch>
        </BrowserRouter>
    );
};

export default App;
