import Tloader from 'react-touch-loader'
import React, {ReactNode} from 'react'
import './index.scss'
import {entries} from "reducer";
import {commonActions} from "action";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";


export interface ComponentsProps {
    dispatch: any,
    refreshOpts?: any,
    onScroll?: any
    onRefresh?: any
    onLoadMore?: any,
    className?: string,
    children: ReactNode,
    page: number,
    totalPage: number,
    changeCommonData: any
}

const screenH = window.screen.height

let scrollNode: any = null

class ScrollVertical extends React.Component<ComponentsProps> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            handleRefresh,
            handleLoadMore,
            props: {
                children,
                page,
                totalPage,
                className
            }
        } = this
        return (
            <Tloader
                className={`vson_scroll ${className}`}
                onRefresh={handleRefresh}
                onLoadMore={handleLoadMore}
                initializing={0}
                hasMore={page < totalPage}
                // autoLoadMore={page < totalPage}
            >
                {
                    children
                }
            </Tloader>
        );
    }

    handleScroll = () => {
        const {
            onScroll = () => {
            }
        } = this.props
        onScroll(scrollNode)
        this.onLazyLoad()
    }

    onLazyLoad = () => {
        const arr = [].slice.call(document.getElementsByClassName('lazy_img'));
        arr.map((e: any) => {
            const {bottom, top} = e.getBoundingClientRect()
            if (bottom < 0 || top > screenH) return
            const src = e.getAttribute('data-src')
            if (!!src) {
                e.setAttribute('src', src)
                e.setAttribute('data-src', '')
            }
        })
    }

    handleRefresh = async (resolve: any, reject: any) => {
        const {
            onRefresh = () => {
            },
            refreshOpts = [],
            dispatch,
            changeCommonData
        } = this.props
        await changeCommonData({page: 1, totalPage: 0})
        for (let i = 0; i < refreshOpts.length; i++) {
            await dispatch(refreshOpts[i])
        }
        await onRefresh()
        resolve()
    }

    handleLoadMore = async (resolve: any) => {
        const {
            onLoadMore = () => {
            },
            page,
            totalPage,
            changeCommonData
        } = this.props
        if (page < totalPage) {
            await changeCommonData({page: page + 1})
            await onLoadMore()
        }
        resolve()
    }

    componentDidMount(): void {
        const {handleScroll} = this
        scrollNode = document.getElementsByClassName('tloader')[0]
        scrollNode.addEventListener('scroll', handleScroll)

    }

    componentDidUpdate(prevProps: Readonly<ComponentsProps>, prevState: Readonly<{}>, snapshot?: any): void {
        this.onLazyLoad()
    }

    componentWillUnmount(): void {
        const {handleScroll} = this
        scrollNode.removeEventListener('scroll', handleScroll)
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

const mapStateToProps = (state: entries) => {
    const {page, totalPage} = state.commonReducer
    return {
        page,
        totalPage
    }
}

function mapDispatchToProps(dispatch: any) {
    const {changeCommonData} = commonActions
    return {
        ...bindActionCreators({changeCommonData}, dispatch),
        dispatch
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScrollVertical)

