import SearchNav from './search_nav'
import GoodsList from './goods_list'
import ScrollVertical from './scroll_vertical'
import VsonTabs from './vson_tabs'
import VsonRange from './vson_range'
import VsonField from './vson_field'
import VsonBanner from "./vson_banner";
import CollectionGoods from "./collection_goods";
import StoreTop from "./store_top";
import StoreGoods from './store_goods'
import AuctionProcess from "./auction_process";
import AllowRight from "./allow_right";
import WingLine from "./wing_line";
import GoodsDetail from "./goods_detail";
import BuyGoodsBottom from "./buy_goods_bottom";
import StoreComments from "./store_comments";
import StoreCommentTop from "./store_comment_top";
import EmptyData from "./empty_data";
import BackHome from "./back_home";
import showDownloadModal from './guide_download_app'

import asyncComponent from "./AsyncComponent";

export {
    SearchNav,
    GoodsList,
    ScrollVertical,
    VsonTabs,
    VsonRange,
    VsonField,
    VsonBanner,
    CollectionGoods,
    StoreTop,
    StoreGoods,
    AuctionProcess,
    AllowRight,
    WingLine,
    GoodsDetail,
    BuyGoodsBottom,
    StoreComments,
    StoreCommentTop,
    EmptyData,
    BackHome,

    showDownloadModal,

    asyncComponent
}
