import {asyncComponent} from 'components'

const GoodsList = asyncComponent(() => import("./goods_list"));
const GoodsTypes = asyncComponent(() => import("./goods_types"));
const SearchGoods = asyncComponent(() => import("./search_goods"));
const AuctionGoodsDetail = asyncComponent(() => import("./auction_goods_detail"));
const FixedGoodsDetail = asyncComponent(() => import("./fixed_goods_detail"));
const ProductGoodsDetail = asyncComponent(() => import("./product_goods_detail"));
const StoreHome = asyncComponent(() => import("./store_home"));

const Mall = {
    goods_list: {
        page: GoodsList,
        params: '/:type'
    },
    goods_types: {
        page: GoodsTypes,
        params: '/:goodsType'
    },
    search_goods: {
        page: SearchGoods,
        params: '/:goodsType/:typeId?/:typeName?'
    },
    auction_goods_detail: {
        page: AuctionGoodsDetail,
        params: '/:shopId'
    },
    fixed_goods_detail: {
        page: FixedGoodsDetail,
        params: '/:shopId'
    },
    product_goods_detail: {
        page: ProductGoodsDetail,
        params: '/:shopId'
    },
    store_home: {
        page: StoreHome,
        params: '/:storeId'
    }
}

export default Mall
