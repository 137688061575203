const jsApiList = [
    "updateAppMessageShareData",
    "updateTimelineShareData"
]

export const config = (config: any) => {
    //@ts-ignore
    window.wx.config({
        debug: false,
        ...config,
        jsApiList
    })
}

export const onShare = (params: any) => {
    const {title, desc, link, imgUrl} = params
    //@ts-ignore
    window.wx.ready(() => {
        //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
        //@ts-ignore
        window.wx.updateAppMessageShareData({
            title, desc, link, imgUrl,
            success: function () {
                // 设置成功
            }
        });

        //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
        //@ts-ignore
        window.wx.updateTimelineShareData({
            title,// 分享标题
            link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl, // 分享图标
            success: function () {
                // 设置成功
            }
        });
    })
}
