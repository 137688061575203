import React from 'react'
import {withRouter} from 'react-router-dom'
import './index.scss'

interface P {
    history: any,
    className?: string
}

interface S {
}


class BackHome extends React.Component<P, S> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            props: {className = ''},
            state: {},
            goHome
        } = this
        return (
            <div className={`home_img ${className}`} onClick={goHome}>
                <img src={'/imgs/home0.png'} alt='' />
            </div>
        );
    }

    componentDidMount(): void {
    }

    goHome = () => {
        const {history} = this.props
        history.push( '/home/mall_home')
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default withRouter(BackHome)
