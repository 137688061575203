import {Fetch} from '../http'

export const getBanners = async () => {
    const {bannerList = []} = await Fetch('/queryBannerList', {type: 1})
    return bannerList
}

export const getHomeGoods = async () => {
    const {fixedList = []} = await Fetch('/shopList')
    return {list: fixedList}
}


export const getGoodsDetail = async (url: string, shopId: string) => {
    const {...goods} = await Fetch(url, {shopId})
    return {goods}
}


export const doCollectionGoods = async (shopId: string, shopType: number, status: number) => {
    const res = await Fetch('/favoritePaint', {shopId, shopType, status})
}

export const getAuctionScenes = async (shopId: string) => {
    const {offerList = []} = await Fetch('/auctionOfferDetails', {shopId})
    return {auctionScenes: offerList}
}


