import React from 'react'
import {createForm, formShape} from 'rc-form';
import {InputItem, SearchBar, Stepper} from 'antd-mobile'
import {entries} from "reducer";
import {commonActions} from "action";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

export interface P {
    auto?: boolean,
    fieldType?: string,
    name: string,
    defaultValue?: any,
    placeholder?: any,
    props?: any,
    form: any,
    changeCommonData: any,
    formFields: any
}

class VsonField extends React.Component<P> {

    static propTypes = {
        form: formShape
    }

    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            props: {
                formFields,
                name,
                fieldType = 'input',
                placeholder,
                defaultValue,
                props = {},
                form: {
                    getFieldProps
                }
            },
            onChange,
            onConfirm
        } = this
        const {
            auto = false,
            clear = true,
            rule = {}
        } = props

        const allProps = {
            ...getFieldProps(name, rule),
            onChange,
            placeholder,
            clear,
            value: formFields[name] || defaultValue,
            ...props
        }
        let node: any = null
        switch (fieldType) {
            case "search":
                node = <SearchBar {...allProps} />
                break;
            case "stepper":
                node = <Stepper showNumber={true} {...allProps} />
                break;
            default:
                if (auto) {
                    allProps.autofocus = 'autofocus'
                }
                node = <InputItem  {...allProps} />
                break
        }
        return (node);
    }

    onChange = async (val: any) => {
        const {changeCommonData, formFields, name} = this.props
        formFields[name] = val
        changeCommonData({formFields: {...formFields}})
    }

    onConfirm = (val: any) => {
        alert(val)
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}


const mapStateToProps = (state: entries) => {
    const {formFields} = state.commonReducer
    return {
        formFields
    }
}

function mapDispatchToProps(dispatch: any) {
    const {changeCommonData} = commonActions
    return {
        ...bindActionCreators({changeCommonData}, dispatch),
        dispatch
    };
}

export default createForm()(connect(
    mapStateToProps,
    mapDispatchToProps
)(VsonField))

// export default createForm()(VsonField)
