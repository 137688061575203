const localDB = {
  DBGetter: (key) => sessionStorage.getItem(key),
  DBSetter: function (key, val) {
    return sessionStorage.setItem(key, typeof val === 'object' ? JSON.stringify(val) : val);
    //return localStorage.setItem(key, typeof val === 'object' ? JSON.stringify(val) : val)
  },
  DBRemove: (key) => sessionStorage.removeItem(key)
}

export default localDB
