import React from 'react'
import {Flex} from 'antd-mobile'
import {EmptyData} from 'components'
import './index.scss'

export interface P {
    options: any[]
}

class StoreComments extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {options} = this.props
        return (
            <div className={'store_comment_list'}>
                {
                    options.map((e, i) => <div className={'store_comment padding'} key={i}>
                        <Flex className={'user'}>
                            <img className={`${e.isAnonymity === '0' ? '' : ''}`} src={e.user.headImg.medium} alt="" />
                            <Flex.Item>
                                <div>{e.isAnonymity === '0' ? '***' : e.user.nickName}</div>
                            </Flex.Item>
                            <div>{e.createTime}</div>
                        </Flex>
                        <div className={'content'}>{e.content}</div>
                    </div>)
                }
                {!options.length && <EmptyData />}
            </div>
        );
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default StoreComments
