import React from 'react'

import './index.scss'

export interface P {
    children: any
}

class WingLine extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {children = '标题'} = this.props
        return (
            <div className={'wing_line item bd_d padding_ud'}>
                <span className={'line'} />
                <span className={'text'}>{children}</span>
                <span className={'line'} />
            </div>
        );
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default WingLine
