import React from 'react'
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import {EmptyData} from 'components'
import './index.scss';
import {entries} from "reducer";

export interface P {
    type?: string,
    square: boolean,
    list: any[],
    auctionStatus: any[],
    goodsTypes: any[],
    history: any
}

class GoodsList extends React.Component<P> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {props: {list, auctionStatus, square = false, type}, navTo} = this
        return (
            !!list.length ? <div className={'goods_list'}>
                {
                    list.map((e: any, i) => {
                        const goodsType = e.type || '' + type
                        return (<div key={i} className={'goods'} onClick={() => navTo(goodsType, e.shopId)}>
                            <div className={`goods_img ${square ? 'square' : ''}`}>
                                <img className={'lazy_img'} src={'/imgs/logo_bg.png'} data-src={e.shopPriImg[0].medium} alt="" />
                                {
                                    goodsType === '1' && <div className={'auction_flag'}>
                                <span className={`status state${e.auctionStatus}`}>
                                    {auctionStatus[1].label}
                                </span>
                                      <span className={'offers'}>{e.offerNum}次出价</span>
                                    </div>
                                }
                            </div>
                            <div className={'title'}>{e.shopName}</div>
                            {
                                goodsType === '1' ? <div>
                                        <div className={'auction_price'}>当前价：<span
                                            className={'price1'}>￥{e.currentPrice}</span></div>
                                        <div className={'over_time'}>结拍时间:{e.auctionDiscountTime}</div>
                                    </div> :
                                    <div className={'price1'}>￥{e.shopPrice}</div>
                            }
                            <a className={'store'} href={'/mall/store_home'}>
                                <span>{e.storeName}</span>
                                <img src='/imgs/right1.png' alt="" />
                            </a>
                        </div>)
                    })
                }
            </div> : <EmptyData />

        );
    }

    navTo = (goodsType?: any, shopId?: string) => {
        const {history, goodsTypes} = this.props
        const {detail} = goodsTypes[goodsType - 1]
        history.push('/mall/' + detail + '/' + shopId)
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

const mapStateToProps = (state: entries) => {
    const {auctionStatus, goodsTypes} = state.commonReducer
    return {
        auctionStatus,
        goodsTypes
    }
}


export default connect(
    mapStateToProps
)(withRouter(GoodsList))

