import React from 'react'
import {Flex} from 'antd-mobile'
import './index.scss'
import {showDownloadModal} from 'components'

class BuyGoodsBottom extends React.Component {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <Flex className={'buy_goods_bottom'} onClick={showDownloadModal}>
                <Flex.Item className={'oprs bd_u'}>
                    <div className={'item'}>
                        <img src="/imgs/servise.png" alt="" />
                        <div>客服</div>
                    </div>
                    <div className={'item'}>
                        <img src="/imgs/shop.png" alt="" />
                        <div>店铺</div>
                    </div>
                    <div className={'item'}>
                        <img src="/imgs/buy_car.png" alt="" />
                        <div>购物车</div>
                    </div>
                </Flex.Item>
                <div className={'opr state0 '}>加入购物车</div>
                <div className={'opr state1 '}>立即购买</div>
            </Flex>
        );
    }

    componentDidMount(): void {
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }
}

export default BuyGoodsBottom
