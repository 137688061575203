import {Fetch} from "../http";

export const getGoodsCategories = async () => {
    const {shopTypeList = []} = await Fetch('/queryShopTypeList')
    return {goodsCategories: shopTypeList}
}

export const getPaginationData = async (obj: any) => {
    const {url, data} = obj
    const {totalPage, ...list} = await Fetch(url, {...data});
    return {totalPage: parseInt(totalPage), list: Object.values(list)[0] || []}
}

export const getStoreHome = async (data: object) => {
    return await Fetch('/queryStoreHome', data)
}

export const login = async (data: object) => {
    return await Fetch('/login', {data, domain: 'm'})
}

export const getWxConfig = async (data: object) => {
    return await Fetch('/weixinWebpageLogin', {data, domain: 'm'})
}

