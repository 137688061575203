import React from 'react'
import {Tabs} from 'antd-mobile'
import './index.scss'
import {commonActions} from 'action'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {entries} from "../../reducer";

export interface P {
    value?: number,
    page?: number,
    name?: string,
    onChange?: any,
    activeColor?: string,
    options: any[],
    tabsProps?: tabsProps,
    children?: any,
    changeCommonData: any,
    formFields: any,
    initValue: any
}

export interface tabsProps {
    label: string,
    value: string
}

const tabsPropsDef: tabsProps = {
    label: 'text',
    value: 'value'
}

export interface S {
    idx: number,
    opt: any[]
}

class VsonTabs extends React.Component<P, S> {
    // 构造
    constructor(props: any) {
        super(props);
        // 初始状态
        this.state = {
            idx: props.initValue || 0,
            opt: []
        };
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {activeColor = '#783838', children = [], page = 4, initValue = 0} = this.props
        const {onChoseTab, state: {idx, opt}, renderContent} = this
        /*
            <div className={'vson_tabs'}>
                {
                    options.map(({title, key}, i) => (
                        <div key={i} onClick={() => onChoseTab(options[i], i)}
                             className={`vson_tab ${idx === i ? 'active' : ''}`}>{title}</div>
                    ))
                }
            </div>
        * */

        return (
            <Tabs tabs={opt}
                  renderTabBar={props => <Tabs.DefaultTabBar {...props} page={page} />}
                  tabBarActiveTextColor={activeColor}
                  tabBarUnderlineStyle={{borderColor: activeColor}}
                  onChange={onChoseTab}
                  initialPage={initValue}
                  page={idx}
            >
                {
                    !!children.length && renderContent
                }
            </Tabs>
        );
    }

    renderContent = (tab: any, index: number) => {
        const {children} = this.props
        return children[index]
    }

    onChoseTab = async (obj?: any, idx?: number) => {
        const {
            props: {
                onChange = () => {
                },
                changeCommonData,
                name = 'tabValue',
                formFields,
                initValue = 0
            },
            state: {opt}
        } = this
        if (!opt.length) return
        formFields[name] = obj ? obj.key : opt[initValue].key
        await changeCommonData({formFields: {...formFields}})
        if (!obj) return
        onChange(obj, idx)
        this.setState({idx: (idx !== undefined && !isNaN(idx)) ? idx : initValue})
    }

    refactorTabs = (newOpts?: any) => {
        const {options = [], tabsProps = tabsPropsDef} = this.props
        const arr = [...(newOpts || options)]
        arr.map(e => {
            e.title = e[tabsProps.label]
            e.key = e[tabsProps.value]
            return e
        })
        this.setState({opt: [...arr]}, this.onChoseTab)
    }

    componentDidMount(): void {
        this.refactorTabs()
    }

    shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        return true
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        const {options: newOpts, value} = nextProps
        const {
            props: {options},
            refactorTabs,
            onChoseTab
        } = this
        if (newOpts.length !== options.length) {
            refactorTabs(newOpts)
            onChoseTab()
        }
        //@ts-ignore
        this.setState({idx: value})
    }
}

const mapStateToProps = (state: entries) => {
    const {formFields} = state.commonReducer
    return {
        formFields
    }
}

function mapDispatchToProps(dispatch: any) {
    const {changeCommonData} = commonActions
    return {
        ...bindActionCreators({changeCommonData}, dispatch),
        dispatch
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VsonTabs)
