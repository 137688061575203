import {combineReducers} from "redux"

import {commonReducer, commonEntry} from './common'
import {mallReducer, MallEntry} from './mall'


const rootReducer = combineReducers({
    commonReducer,
    mallReducer
});

export default rootReducer

export interface entries {
    mallReducer: MallEntry,
    commonReducer: commonEntry
}
