import {createStore, applyMiddleware} from "redux";
import thunkMiddleware from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";

import reducers from "reducer";

const middlewares = [thunkMiddleware];
const middleWareEnhancer = applyMiddleware(...middlewares);
const stores = createStore(
    reducers,
    composeWithDevTools(middleWareEnhancer)
);

export default stores
