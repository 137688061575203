import {Modal} from 'antd-mobile'

const {alert} = Modal

const showDownloadModal = () => alert('提示', '下载APP了解更多精彩内容，是否前去下载？', [
    {text: '取消', onPress: () => console.log('cancel')},
    {
        text: '确定', onPress: () => {
            window.location.href = 'http://www.leshoucang.com.cn/wuweizhai_APP.htm'
        }
    },
])

export default showDownloadModal
