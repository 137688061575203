export const addParams = (arr: any[]) => {
    let s = ''
    arr.map(e => {
        if (!!e && e !== 0) {
            (s += ('/' + e))
        }
    })
    return s
}

export const getFetchParams = (params: any, contentType: number) => {
    Object.entries(params).map(([k, v]) => {
        if (v === 0 || !!v) return
        delete params[k]
    })
    let p: any = null
    switch (contentType) {
        case 1:
            p = JSON.stringify(params)
            break;
        case 2:

            break;
        default:
            p = spliceParams(params)
            break
    }
    return p
}

export const spliceParams = (obj: object) => {
    let s: string = ''
    Object.entries(obj).map(([k, v], i) => {
        s += (!!i ? '&' : '') + k + '=' + v
    })
    return s
}

export const getParamsBySearch = (str: string) => {
    const params: any = {}
    str.trim().replace(/\?/g, '').split('&').map(e => {
        const [k, v] = e.split('=')
        params[k] = v
    })
    return params
}

